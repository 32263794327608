<template>
    <v-dialog v-model="dialogEmpleado" persistent max-width="1200px" max-height=" 1200px">
        <v-card class="scroll">
            <v-toolbar dark color="bluePrimary" style="border-radius: 10px 10px 0px 0px;">

                <v-toolbar-title>Información personal</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-btn icon dark @click="closeDialog()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>
            <div class="wrapper">
                <div class="one" v-if="showImage()">
                    <v-avatar size="200" height="200" width="200" class="xs12 sm6 md4 lg3 xl2 mt-7 mb-7 mr-2">
                        <v-img
                            src="@/assets/img/default_user.png"
                            style="object-fit: cover !important; object-position: top !important;"
                        ></v-img>
                    </v-avatar>
                </div>
                <div class="two" :class="!this.$vuetify.breakpoint.xs && !this.$vuetify.breakpoint.md ? 'mx-2 mr-2' : 'mx-4'">
                    <v-row class="mt-2">
                        <v-col cols="12" class="no-padding d-flex justify-start">
                            <p class="text-center text-h5 font-weight-bold bluePrimary--text">{{
                                getFullName }}</p>
                        </v-col>
                        <v-col cols="12" sm="3" class="no-padding">
                            <div class="text-start">
                                <p class="text-caption no-padding">Tipo documento</p>
                                <p class="text-subtitle-2">{{ dataObject.persona?.documento_persona[0]?.tipo_documento?.nombre?.toUpperCase() }}</p>
                            </div>
                        </v-col>
                        <v-col cols="12" sm="3" class="no-padding">
                            <div class="text-start">
                                <p class="text-caption no-padding">N° documento</p>
                                <p class="text-subtitle-2">{{dataObject.persona?.documento_persona[0]?.numero}} </p>
                            </div>
                        </v-col>
                        <v-col cols="12" sm="3" class="no-padding">
                            <div class="text-start">
                                <p class="text-caption no-padding">Unidad organizativa</p>
                                <p class="text-subtitle-2">{{dataObject.empleado_puesto[0]?.unidad_organizativa?.nombre?.toUpperCase()}}</p>
                            </div>
                        </v-col>
                        <v-col cols="12" sm="3" class="no-padding">
                            <div class="text-start">
                                <p class="text-caption no-padding">Plaza</p>
                                <p class="text-subtitle-2">{{ dataObject.empleado_puesto[0]?.detalle_plaza?.detalle_plaza.nombre?.toUpperCase() }}</p>
                            </div>
                        </v-col>
                        <v-col cols="12" sm="3" class="no-padding">
                            <div class="text-start">
                                <p class="text-caption no-padding">Fecha de contratación</p>
                                <p class="text-subtitle-2">{{ formatDate(dataObject?.fecha_ingreso) }}</p>
                            </div>
                        </v-col>
                        <v-col cols="12" sm="3" class="no-padding">
                            <div class="text-start">
                                <p class="text-caption no-padding">Modalidad de contratación</p>
                                <p class="text-subtitle-2">{{dataObject?.empleado_puesto[0]?.detalle_plaza?.sistema_pago?.nombre?.toUpperCase()}}</p>
                            </div>
                        </v-col>
                        <v-col cols="12" sm="3" class="no-padding">
                            <div class="text-start">
                                <p class="text-caption no-padding">Salario real</p>
                                <p class="text-subtitle-2">{{ dataObject?.empleado_puesto[0]?.detalle_plaza?.salario_minimo }}</p>
                            </div>
                        </v-col>
                        <v-col cols="12" sm="3" class="no-padding">
                            <div class="text-start">
                                <p class="text-caption no-padding">Cargo</p>
                                <p class="text-subtitle-2">{{ dataObject.empleado_puesto[0]?.cargo_funcional?.nombre?.toUpperCase() }}</p>
                            </div>
                        </v-col>
                        <v-col cols="12" sm="3" class="no-padding">
                            <div class="text-start">
                                <p class="text-caption no-padding">Tiempo de servicio</p>
                                <p class="text-subtitle-2">{{ dataObject?.tiempo_servicio?.toUpperCase() }}</p>
                            </div>
                        </v-col>
                        <v-col cols="12" sm="3" class="no-padding">
                            <div class="text-start">
                                <p class="text-caption no-padding">Estado puesto</p>
                                <p class="text-subtitle-2">{{ dataObject?.empleado_puesto[0]?.detalle_plaza?.estado_plaza?.activo ? 'ACTIVO' : 'INACTIVO' }}</p>
                            </div>
                        </v-col>
                        <v-col cols="12" sm="3" class="no-padding">
                            <div class="text-start">
                                <p class="text-caption no-padding">Estado persona</p>
                                <p class="text-subtitle-2">{{ dataObject?.persona?.activo ? 'ACTIVO' : 'INACTIVO' }}</p>
                            </div>
                        </v-col>

                    </v-row>
                </div>
            </div>
            <v-toolbar dark color="bluePrimary" class="scroll" style="border-radius: 10px 10px 0px 0px;">
                <v-toolbar-title>Datos de la plaza</v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-row class="mt-2 mx-4">
                <v-col cols="12" sm="6" md="3" class="no-padding">
                    <div class="text-start">
                        <p class="text-caption no-padding">Denominación de plaza</p>
                        <p class="text-subtitle-2">{{ dataObject?.empleado_puesto[0]?.detalle_plaza?.detalle_plaza?.nombre?.toUpperCase() }}</p>
                    </div>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="no-padding">
                    <div class="text-start">
                        <p class="text-caption no-padding">Institución</p>
                        <p class="text-subtitle-2">{{ dataObject?.empleado_puesto[0]?.unidad_organizativa?.establecimiento?.institucion?.nombre?.toUpperCase() }}</p>
                    </div>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="no-padding">
                    <div class="text-start">
                        <p class="text-caption no-padding">Número de horas</p>
                        <p class="text-subtitle-2">{{ dataObject?.empleado_puesto[0]?.horas_plaza }}</p>
                    </div>
                </v-col>

                <v-col cols="12" sm="6" md="3" class="no-padding">
                    <div class="text-start">
                        <p class="text-caption no-padding">Cifrado presupuestario</p>
                        <p class="text-subtitle-2">{{ dataObject?.empleado_puesto[0]?.detalle_plaza?.cifrado?.codigo  }}</p>
                    </div>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="no-padding">
                    <div class="text-start">
                        <p class="text-caption no-padding">Unidad presupuestaria</p>
                        <p class="text-subtitle-2">{{ dataObject?.empleado_puesto[0]?.detalle_plaza?.cifrado?.unidad_presupuestaria?.codigo }}</p>
                    </div>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="no-padding">
                    <div class="text-start">
                        <p class="text-caption no-padding">Línea de trabajo</p>
                        <p class="text-subtitle-2">{{ dataObject?.empleado_puesto[0]?.detalle_plaza?.cifrado?.codigo_linea_trabajo }}</p>
                    </div>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="no-padding">
                    <div class="text-start">
                        <p class="text-caption no-padding">Partida</p>
                        <p class="text-subtitle-2">{{ dataObject?.empleado_puesto[0]?.detalle_plaza?.partida }}</p>
                    </div>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="no-padding">
                    <div class="text-start">
                        <p class="text-caption no-padding">Subpartida</p>
                        <p class="text-subtitle-2">{{ dataObject?.empleado_puesto[0]?.sub_numero }}</p>
                    </div>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="no-padding">
                    <div class="text-start">
                        <p class="text-caption no-padding">Tipo de financiamiento</p>
                        <p class="text-subtitle-2">{{ dataObject?.empleado_puesto[0]?.detalle_plaza?.tipo_financiamiento?.nombre?.toUpperCase() }}</p>
                    </div>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="no-padding">
                    <div class="text-start">
                        <p class="text-caption no-padding">Presupuesto que financia
                        </p>
                        <p class="text-subtitle-2">{{ dataObject?.empleado_puesto[0]?.detalle_plaza?.presupuesto_financia?.nombre?.toUpperCase() }}</p>
                    </div>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="no-padding">
                    <div class="text-start">
                        <p class="text-caption no-padding">Salario base</p>
                        <p class="text-subtitle-2"> {{ dataObject?.empleado_puesto[0]?.detalle_plaza?.salario_minimo ? '$ '+ dataObject?.empleado_puesto[0]?.detalle_plaza?.salario_minimo : '' }}</p>
                    </div>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="no-padding">
                    <div class="text-start">
                        <p class="text-caption no-padding">Salario máximo</p>
                        <p class="text-subtitle-2"> {{ dataObject?.empleado_puesto[0]?.detalle_plaza?.salario_maximo ? '$ ' + dataObject?.empleado_puesto[0]?.detalle_plaza?.salario_maximo : '' }}</p>
                    </div>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="no-padding">
                    <div class="text-start">
                        <p class="text-caption no-padding">Especifico de gasto</p>
                        <p class="text-subtitle-2">{{ dataObject?.empleado_puesto[0]?.detalle_plaza?.especifico_gasto?.codigo }}</p>
                    </div>
                </v-col>


                <v-col cols="12" sm="6" md="3" class="no-padding">
                    <div class="text-start">
                        <p class="text-caption no-padding">Estado plaza</p>
                        <p class="text-subtitle-2"> {{ dataObject?.empleado_puesto[0]?.detalle_plaza?.detalle_plaza?.activo ? "ACTIVO" :  "INACTIVO"}}</p>
                    </div>
                </v-col>
            </v-row>
            <div class=" d-flex justify-center">
                <v-btn color="bluePrimary" dark outlined rounded class="text-capitalize ma-2" @click="closeDialog()">
                    Cerrar
                </v-btn>
            </div>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    name: "detalleEmpleado",
    props: {
        dataObject: {
            type: Object,
            required: true,
            default: () => ({})
        },
        closeDialog: {
            type: Function,
            required: true
        },
        dialogEmpleado: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
        };
    },
    methods: {
        // formatear fecha en dia/mes/año
        formatDate(date) {
            if (date) {
                const [year, month, day] = date.split("-");
                return `${day}/${month}/${year}`;
            }else{
                return 'Sin fecha';
            }
        },
        // mostrar imagen cuando no sea xs y md
        showImage() {
            return !this.$vuetify.breakpoint.xs && !this.$vuetify.breakpoint.md;
        },
    },
    mounted() {
        // mounted
    },
    computed: {
        getFullName() {
            let fullName = "";
            if (this.dataObject?.persona?.primer_nombre) {
                fullName += this.dataObject?.persona?.primer_nombre;
            }
            if (this.dataObject?.persona?.segundo_nombre) {
                fullName += " " + this.dataObject?.persona?.segundo_nombre;
            }
            if (this.dataObject?.persona?.primer_apellido) {
                fullName += " " + this.dataObject?.persona?.primer_apellido;
            }
            if (this.dataObject?.persona?.segundo_apellido) {
                fullName += " " + this.dataObject?.persona?.segundo_apellido;
            }
            return fullName.toUpperCase();
        },
    },
    created() {
    },
};
</script>
<style scope>
/* styles */
.no-padding {
    margin-bottom: 0 !important;
    padding: 0 !important;
}

.wrapper {
    display: flex;
    margin: 10px;
}

.one {
    flex: 1;
    background-size: cover;
}

.two {
    flex: 6;
}

.scroll {
    overflow: hidden;
}
.v-dialog {
    border-radius: 10px;
    margin: 24px;
    overflow-y: auto;
    pointer-events: auto;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    width: 100%;
    z-index: inherit;
    outline: none;
    /* box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12); */
}
</style>
