<template>
    <v-row>
      <v-col>
        <v-text-field
            v-model="search"
            outlined
            dense
            :label="labelProps"
            append-icon="mdi-magnify"
            :class="extraclass"
            @input="filtrar"
            :maxlength="maxlength"
            @keyup.enter="filtrar"
        ></v-text-field>
      </v-col>
    </v-row>
  </template>
  <script>
  export default {
    name: "appFiltros",
  
    props: {
      size: {
        type: String,
        default: "30%",
      },
      extraclass:{
        type: String,
        default: 'rounded-xl',
      },
      labelProps: {
        type: String,
        default: "Buscar",
      },
	    maxlength: {
				type: String,
				default: "100",
			},
    },
  
    data: () => ({
      search: "",
    }),
    methods: {
      filtrar() {
          this.$emit("filtrar", this.search);
      },
    },
  
    computed: {
      customSize() {
        return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm ? (this.size = "100%") : (this.size = "30%");
      },
    }
  }
  </script>