import {
	http_client
} from "@/plugins/http_client";

const urlBase = "api/v1";
let url = '/api/v1/refrenda'

const obtenerRefrendas = (params) => {
	return http_client('/api/v1/refrenda', params, 'GET')
}

const obtenerTipoContratos = (params) => {
	return http_client('/api/sistema-pago/catalogo', params, 'GET')
}

const createRefrenda = (params = {}) => {
	return http_client(`${url}`,params, 'post') 
}

const getRefrendaByID = (id, params = {}) => {
	return http_client(`${url}/${id}`,params, 'get') 
}


//step estado actual
const structureExpansionPannel = (id_refrenda, id_detalle_plaza, params = {}) => {
	return http_client(`${url}/estructura-cifrado/anterior/${id_refrenda}/${id_detalle_plaza}`,params, 'get') 
}
const dataTableExpansionPannel = (id_refrenda, id_sistema_pago, params = {}) => {
	return http_client(`${url}/empleado-puesto/anterior/${id_refrenda}/${id_sistema_pago}`,params, 'get') 
}


//step nombreamiento y movimientos
//end12
const searchEmpleadoPuesto = (id_refrenda, params = {}) => {
	return http_client(`${url}/empleado-puesto/busqueda/anterior/${id_refrenda}`,params, 'get') 
}
//end13
const tipoMovimiento = (params = {}) => {
	return http_client(`${url}/tipo-movimiento`,params, 'get') 
}
//end14
const unidadPresupuestaria = (params = {}) => {
	return http_client(`${url}/unidad-presupuestaria`,params, 'get') 
}
//end15
const lineaTrabajo = (params = {}) => {
	return http_client(`${url}/linea-trabajo`,params, 'get') 
}
//end16
const plazas = (params = {}) => {
	return http_client(`${url}/plazas`,params, 'get') 
}
//end17
const cifradoPlaza = (idRefrenda, params = {}) => {
	return http_client(`${url}/cifrado-plaza/${idRefrenda}`,params, 'get')
}

//end18
const agregarAccionPersonal = (id_refrenda, params = {}) => {
	return http_client(`${url}/accion-personal/${id_refrenda}`, params, 'post')
}

//end22
const obtenerAccionPersonal = (id_refrenda, params = {}) => {
	return http_client(`${url}/accion-personal/${id_refrenda}`, params, 'get')
}


const eliminarAccionPersonal = (id_accion_personal, params = {}) => {
	return http_client(`${url}/accion-personal/${id_accion_personal}`, params, 'delete')
}



//step cifrado
const donwloadPlantillaCrifrado = (params = {}) => {
	return http_client(`${url}/plantilla-cifrado`,params, 'get',{}, true, "arraybuffer") 
}
const addCifrado = (idRefrenda, params = {}, headers = {}) => {
	return http_client(`${url}/cifrados/${idRefrenda}`,params, 'post', headers) 
}
const getCifrado = (idRefrenda, params = {}) => {
	return http_client(`${url}/cifrados/${idRefrenda}`,params, 'get') 
}
 //step plaza
const donwloadPlantillaPlaza = (params = {}) => {
	return http_client(`${url}/plantilla-plaza`,params, 'get',{}, true, "arraybuffer") 
}
const addPlaza = (idRefrenda, params = {}, headers = {}) => {
	return http_client(`${url}/plazas/${idRefrenda}`,params, 'post', headers) 
}
const getPlaza = (idRefrenda, params = {}) => {
	return http_client(`${url}/plazas/${idRefrenda}`,params, 'get') 
}
// verificacion de empleados
const structureExpansionPannelVerificacion = (id_refrenda, id_sistema_pago, params = {}) => {
	return http_client(`${url}/estructura-cifrado/actual/${id_refrenda}/${id_sistema_pago}`,params, 'get')
}
const plazaAsignacionActual = (id_refrenda, id_detalle_plaza, params = {}) => {
	return http_client(`${url}/empleado-puesto/actual/${id_refrenda}/${id_detalle_plaza}`,params, 'get')
}

const VerificacionPlaza = (idRefrenda, params = {}) => {
	return http_client(`${url}/verificacion/${idRefrenda}`,params, 'put')
}
const desvincularEmpleadoPuesto = (id_refrenda_empleado_puesto, params = {}) => {
	return http_client(`${url}/empleado-puesto/actual/desvincular-plaza/${id_refrenda_empleado_puesto}`,params, 'delete')
}
const empleadoVerificados = (idRefrenda, params = {}) => {
	return http_client(`${url}/verificados/${idRefrenda}`,params, 'get')
}

//step 5

//end32
const obtenerEmpleadoPuestoPendiente = (idRefrenda, params = {}) => {
	return http_client(`${url}/empleado-puesto/pendientes/${idRefrenda}`,params, 'get')
}

//end35
const obtenerPlazaDisponibleUnidad = (idRefrenda, params = {}) => {
	return http_client(`${url}/actual/unidad-presupuestaria/${idRefrenda}`,params, 'get')
}

//end36
const obtenerPlazaDisponibleLineaTrabajo = (idRefrenda, params = {}) => {
	return http_client(`${url}/actual/linea-trabajo/${idRefrenda}`,params, 'get')
}

//end37
const obtenerPlazaDisponible = (idRefrenda, params = {}) => {
	return http_client(`${url}/actual/plazas/${idRefrenda}`,params, 'get')
}

//end38
const filtrarCifradoPlazaActual = (idRefrenda, params = {}) => {
	return http_client(`${url}/actual/cifrado-plaza/${idRefrenda}`,params, 'get')
}

//end39
const asignacionPresupuestaria = (params = {}) => {
	return http_client(`${url}/empleado-puesto/asignacion-presupuestaria`,params, 'post')
}

// end40
const exportarReporteEmpleadoPuesto = (idRefrenda, params = {}) => {
	return http_client(`${url}/empleado-puesto/pendientes/reporte/${idRefrenda}`,params, 'get',{}, true, "arraybuffer")
}

// endd41
const getCantidadVerificados = (idRefrenda, params = {}) => {
	return http_client(`${url}/verificados/${idRefrenda}`,params, 'get')
}

const getListadoRefrendas = async (id, filter) => await http_client(`${url}/listado-refrenda/${id}`, filter, 'get')

const postEnviarSolicitudesAprobacion = async (body) => await http_client(`${url}/aprobaciones/enviar-aprobaciones`, body, 'post')

const getEstadoRefrenda = async (id) => await http_client(`${url}/estado-refrenda/${id}`, {}, 'get')

const getEstadoAutorizacionesRefrenda = async (id) => await http_client(`${url}/ver-autorizaciones-refrenda/${id}`, {}, 'get')

const deleteAsignacionCifrado = async (id) => await http_client(`${url}/asignacion-cifrado/${id}`, {}, 'delete')

const postAsignacionCifrado = async (id) => await http_client(`${url}/asignacion-cifrado/${id}`, {}, 'post')

const getDocumentoRefrenda = async (id, tipo) => await http_client(`${url}/get-documento/${id}`, tipo, 'get');

const postDocumentoFirmado = async (id, form) => await http_client(`${url}/subir-documento-firmado/${id}`, form, 'post');

const postIniciarMigracion = async (id) => await http_client(`${url}/iniciar-migracion/${id}`, {}, 'post');

const getListRefrendasAprobar = async(filtros) => await http_client(`${urlBase}/refrenda/aprobaciones/listado`, filtros, "get");

const postAprobarRefrenda = async (id) => await http_client(`${urlBase}/refrenda/aprobaciones/aprobar`, id, 'post');

const postMigracionMongo = async (id) => await http_client(`${url}/${id}`, {}, 'post');

const getConsultarRefrenda = async (id) => await http_client(`${url}/${id}`, {}, 'get');

const getDocumento = async (id, tipo) => await http_client(`${url}/get-documento/${id}`, tipo, 'get');
const StatusMatchEmpleados = async (id_refrenda, params = {}) => await http_client(`${url}/finalizada/${id_refrenda}`, params, 'get');

//step6
const newSructureExpansionPannel = (id_refrenda, id_sistema_pago, params = {}) => {
	return http_client(`${url}/estructura-cifrado/actual/${id_refrenda}/${id_sistema_pago}`,params, 'get') 
}
const newDataTableExpansionPannel = (id_refrenda, id_detalle_plaza, params = {}) => {
	return http_client(`${url}/empleado-puesto/resumen-final/${id_refrenda}/${id_detalle_plaza}`,params, 'get') 
}
const postFinalizarRefrenda = (id_refrenda, params={})=>{
	return http_client(`${url}/finalizar/${id_refrenda}`,{}, 'post') 
}
export default {
	createRefrenda,
	// geteRefrendaByID,
	obtenerRefrendas,
	obtenerTipoContratos,
	getRefrendaByID,
	// getEstadosActual,
	getRefrendaByID,
	obtenerTipoContratos,
	//step estado actual
	structureExpansionPannel,
	dataTableExpansionPannel,
	//step nombreamiento y movimientos
	searchEmpleadoPuesto,
	tipoMovimiento,
	unidadPresupuestaria,
	lineaTrabajo,
	plazas,
	cifradoPlaza,
	//step cifrado
	donwloadPlantillaCrifrado,
	addCifrado,
	getCifrado,
	obtenerAccionPersonal,
	agregarAccionPersonal,
	eliminarAccionPersonal,
	//step plaaza
	donwloadPlantillaPlaza,
	addPlaza,
	getPlaza,
	obtenerEmpleadoPuestoPendiente,
	obtenerPlazaDisponibleUnidad,
	obtenerPlazaDisponibleLineaTrabajo,
	obtenerPlazaDisponible,
	exportarReporteEmpleadoPuesto,
	filtrarCifradoPlazaActual,
	asignacionPresupuestaria,
    //step verificacion de empleados
	structureExpansionPannelVerificacion,
	plazaAsignacionActual,
	VerificacionPlaza,
	desvincularEmpleadoPuesto,
	empleadoVerificados,
	getListadoRefrendas,
	postEnviarSolicitudesAprobacion,
	getEstadoRefrenda,
	getEstadoAutorizacionesRefrenda,
	deleteAsignacionCifrado,
	postAsignacionCifrado,
	getDocumentoRefrenda,
	postDocumentoFirmado,
	postIniciarMigracion,
	getListRefrendasAprobar,
	postAprobarRefrenda,
	postMigracionMongo,
	getConsultarRefrenda,
	getDocumento,
	getCantidadVerificados,
	StatusMatchEmpleados,
	//step6
	newSructureExpansionPannel,
	newDataTableExpansionPannel,
	postFinalizarRefrenda,

}